var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-wrap",
    class: _vm.classname
  }, [_c("div", {
    staticClass: "progress-wrap"
  }, _vm._l(_vm.fileList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "progress-item"
    }, [_c("div", {
      staticClass: "form-process"
    }, [_c("a-progress", {
      staticClass: "progress",
      attrs: {
        percent: item.percent
      }
    })], 1), _c("div", {
      staticClass: "icon-box"
    }, [_c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.status == "done",
        expression: "item.status == 'done'"
      }],
      staticClass: "process-icon",
      attrs: {
        type: "eye"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(item, _vm.uploadType);
        }
      }
    })], 1)]);
  }), 0), _c("a-upload", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.state != "uploading",
      expression: "state != 'uploading'"
    }],
    staticClass: "upload-box",
    attrs: {
      listType: _vm.listType,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: _vm.disabled ? {
        showRemoveIcon: false,
        showPreviewIcon: false
      } : _vm.showUploadList,
      disabled: _vm.disabled,
      accept: _vm.supportFileType
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.previewImg
    }
  }, [_c("a-icon", {
    staticClass: "process-icon",
    attrs: {
      type: "cloud-sync"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };