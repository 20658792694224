import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { DcVideoApi } from "@/api";
import { formatTextareaToArray } from "@/utils/help";
export default {
  name: "DcReviewCheck",
  components: {},
  props: {
    editId: [Array],
    action: {
      type: String,
      default: function _default() {
        return "pass";
      }
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      submiting: false,
      fetching: false,
      item: {},
      templates: [],
      // 模板列表
      templateCode: null // 模板代码
    };
  },

  computed: {
    isDisabled: function isDisabled() {
      return this.action == "view";
    },
    ids: function ids() {
      return this.editId.join("\r\n");
    },
    status: function status() {
      return this.action == "pass" ? 1 : 2; // 1.通过,2.驳回
    },
    templateMsg: function templateMsg() {
      var _this = this;
      var it = this.templates.find(function (item) {
        return item.code === _this.templateCode;
      }) || {};
      return it === null || it === void 0 ? void 0 : it.message;
    }
  },
  created: function created() {
    if (this.action == "unpass") {
      this.loadTemplates();
    }
  },
  methods: {
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    loadTemplates: function loadTemplates() {
      var _this2 = this;
      this.spinning = true;
      DcVideoApi.rejectreason().then(function (res) {
        _this2.spinning = false;
        _this2.templates = res;
      }).catch(function () {});
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this3 = this;
      this.form.validateFields(function (error, values) {
        if (!error) {
          var params = _objectSpread({}, values);
          params.status = _this3.status;

          // id多行
          params.videoids = formatTextareaToArray(params.videoids);

          // console.log('提交的数据', params);
          _this3.submiting = true;
          DcVideoApi.batchcheck(_objectSpread({}, params)).then(function () {
            _this3.toast(_this3.$t('common.success'), "success");
            _this3.$emit("submit");
          }).catch(function (err) {
            _this3.submiting = false;
            // this.toast("操作失败," + err, "error");
          });
        }
      });
    },
    handleReason: function handleReason(val) {
      this.templateCode = val;
      this.form.resetFields(['reason']);
    }
  }
};