var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-drawer", {
    attrs: {
      title: _vm.newTitle,
      width: _vm.width,
      visible: _vm.visible,
      "body-style": {
        paddingBottom: "80px"
      },
      destroyOnClose: true
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.type")
    }
  }, [_c("a-radio-group", {
    attrs: {
      options: _vm.formatTypeList,
      disabled: _vm.editId ? true : false
    },
    model: {
      value: _vm.postType,
      callback: function callback($$v) {
        _vm.postType = $$v;
      },
      expression: "postType"
    }
  })], 1), _vm.postType == 1 ? [!_vm.editId ? _c("dragupload", {
    attrs: {
      fileList: _vm.fileList
    },
    on: {
      "update:fileList": function updateFileList($event) {
        _vm.fileList = $event;
      },
      "update:file-list": function updateFileList($event) {
        _vm.fileList = $event;
      }
    }
  }) : _vm._e()] : [_c("dragupload", {
    attrs: {
      fileList: _vm.photoList,
      max: 18,
      supports: [".jpg", ".jpeg", ".png", ".gif"],
      fileSize: 10240
    },
    on: {
      "update:fileList": function updateFileList($event) {
        _vm.photoList = $event;
      },
      "update:file-list": function updateFileList($event) {
        _vm.photoList = $event;
      }
    }
  })], _vm.postType == 1 ? _vm._l(_vm.fileList, function (item, index) {
    return _c("Form", {
      key: index,
      ref: "formref",
      refInFor: true,
      attrs: {
        item: item,
        index: index + 1,
        tagSource: _vm.tags,
        postType: _vm.postType
      },
      on: {
        removed: _vm.handleFormDelete
      }
    });
  }) : [_c("Form", {
    ref: "formref",
    attrs: {
      item: _vm.postItem,
      index: 1,
      tagSource: _vm.tags,
      postType: _vm.postType,
      photoList: _vm.photoList
    },
    on: {
      removed: _vm.handleFormDelete,
      delimg: _vm.delimg
    }
  })], _c("div", {
    staticClass: "actions"
  }, [_c("a-button", {
    style: {
      marginRight: "8px"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };