var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-upload-dragger", _vm._g(_vm._b({
    attrs: {
      name: "file",
      multiple: true,
      accept: _vm.accept,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: false
    },
    on: {
      change: _vm.handleUploadChanged
    }
  }, "a-upload-dragger", _vm.$attrs, false), _vm.$listeners), [_c("p", {
    staticClass: "ant-upload-drag-icon"
  }, [_c("a-icon", {
    attrs: {
      type: "cloud-upload"
    }
  })], 1), _c("p", {
    staticClass: "ant-upload-text"
  }, [_vm._v("Click or drag file to this area to upload")]), _c("p", {
    staticClass: "ant-upload-hint"
  }, [_vm._v(_vm._s(_vm.$t("dcReview.dragUploadHint", [_vm.accept, _vm.formatFilesize, _vm.max])))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };