import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import { mapState } from 'vuex';
import Form from "./_form.vue";
import dragupload from "./dragupload.vue";
import { DcVideoApi } from "@/api";
import previewMixin from "@/utils/previewMixin";
import { filenameWithoutExtenstion } from '@/utils/help';
export default {
  components: {
    dragupload: dragupload,
    Form: Form
  },
  mixins: [previewMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editId: [String, Number],
    width: {
      type: Number,
      default: 650
    }
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) {
        this.fileList = [];
        this.photoList = [];
        this.postItem = {};
        this.loadTags();
        if (this.editId) {
          this.find();
        }
      }
    },
    photoList: function photoList(newVal) {
      var _this$postItem;
      if ((_this$postItem = this.postItem) !== null && _this$postItem !== void 0 && _this$postItem.title) return;else if (newVal.length) {
        this.postItem.title = filenameWithoutExtenstion(newVal[0].name);
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState('dcVideo', ['typeList'])), {}, {
    newTitle: function newTitle() {
      if (this.editId) {
        return "".concat(this.$t('dcReview.edit'), "[").concat(this.editId, "]");
      }
      return this.$t('dcReview.add');
    },
    formatTypeList: function formatTypeList() {
      return this.typeList.map(function (it) {
        it.value = it.id;
        it.label = it.name;
        return it;
      });
    }
  }),
  mounted: function mounted() {
    this.loadTags();
    if (this.editId) {
      this.find();
    }
  },
  data: function data() {
    return {
      fileList: [
        // { name: "aaaa", uid: "1", status: 'done', percent: 100, contenturl: 'https://static1.knowhere.io/file/b69beef1d8fb479398939c28f274c662.jpg'},
      ],
      photoList: [],
      collapses: {},
      // 折叠数据

      // imgProportion: [1920, 800],

      submiting: false,
      // 提交状态
      uloading: false,
      // 用户搜索状态

      timer: null,
      userSource: [],
      tags: [],
      postType: 2,
      postItem: {}
    };
  },
  methods: {
    onClose: function onClose() {
      this.$emit("cancel");
    },
    onSubmit: function onSubmit() {
      var _this = this;
      var forms = this.$refs.formref;

      // 先验证表单
      var formData = [];
      if (this.postType == 1) {
        if (forms.length == 0) {
          this.toast(this.$t('common.nodata'), 'error');
          return false;
        }
        forms.forEach(function (form) {
          var p = new Promise(function (resolve, reject) {
            form.submit().then(function (data) {
              resolve(data);
            }).catch(function () {
              reject();
            });
          });
          formData.push(p);
        });
      } else {
        var p = new Promise(function (resolve, reject) {
          forms.submit().then(function (data) {
            resolve(data);
          }).catch(function () {
            reject();
          });
        });
        formData.push(p);
      }
      Promise.all(formData).then(function (data) {
        // console.log('data', data);
        _this.submiting = true;
        if (_this.editId) {
          DcVideoApi.update(_objectSpread({
            id: _this.editId
          }, data[0])).then(function () {
            _this.toast(_this.$t('common.success'), 'success');
            _this.submiting = false;
            _this.$emit('submit');
          }).catch(function () {
            _this.submiting = false;
          });
        } else {
          DcVideoApi.batchAdd({
            list: _toConsumableArray(data),
            type: _this.postType
          }).then(function () {
            _this.toast(_this.$t('common.success'), 'success');
            _this.submiting = false;
            _this.$emit('submit');
          }).catch(function () {
            _this.submiting = false;
          });
        }
      }).catch(function () {});
    },
    find: function find() {
      var _this2 = this;
      // 查询视频详情
      this.fileList = [];
      DcVideoApi.findone({
        id: this.editId
      }).then(function (data) {
        _this2.postType = data.type;
        if (data.type == 2) {
          data.creator = {
            key: data.userid,
            label: data.username
          };
          _this2.postItem = data;
          _this2.photoList = data.contenturl.split(',').map(function (it) {
            return {
              status: 'done',
              percent: 100,
              contenturl: it,
              response: it
            };
          });
        } else {
          data.creator = {
            key: data.userid,
            label: data.username
          };
          data.status = 'done';
          data.percent = 100;
          _this2.fileList.push(data);
        }
      }).catch(function () {});
    },
    // 标签列表
    loadTags: function loadTags() {
      var _this3 = this;
      var params = {
        ispagination: false,
        sceneid: 1,
        sortbysort: true,
        isdesc: true
      };
      DcVideoApi.lablesV2(_objectSpread({}, params)).then(function (data) {
        _this3.tags = data.list || [];
        // console.log(this.tags);
      }).catch(function () {});
    },
    handleFormDelete: function handleFormDelete(uid) {
      this.fileList = this.fileList.filter(function (item) {
        return uid != item.uid;
      });
    },
    delimg: function delimg(index) {
      this.photoList = this.photoList.filter(function (it, ind) {
        return index != ind;
      });
    }
  }
};