import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrap",
    class: {
      error: !_vm.hasVideo
    }
  }, [_vm.postType == 1 ? [!_vm.item.id ? _c("span", {
    staticClass: "form-num"
  }, [_vm._v(_vm._s(_vm.$attrs.index))]) : _vm._e(), !_vm.item.id ? _c("a-icon", {
    staticClass: "form-delete",
    style: {
      color: "red",
      fontSize: "18px"
    },
    attrs: {
      type: "delete"
    },
    on: {
      click: function click($event) {
        return _vm.handleDelete(_vm.item);
      }
    }
  }) : _vm._e(), !_vm.item.id ? _c("a-icon", {
    staticClass: "form-collapse",
    class: {
      reverse: !_vm.collapsed
    },
    style: {
      fontSize: "18px"
    },
    attrs: {
      type: "down"
    },
    on: {
      click: _vm.collapse
    }
  }) : _vm._e()] : [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.photoList.length,
      expression: "photoList.length"
    }],
    staticClass: "drag_wrap"
  }, [_c("draggable", {
    staticClass: "list_group",
    attrs: {
      list: _vm.photoList
    }
  }, _vm._l(_vm.photoList, function (it, index) {
    return _c("div", {
      key: index,
      staticClass: "list_item"
    }, [_c("span", {
      staticClass: "no"
    }, [_vm._v(_vm._s(index + 1))]), _c("img", {
      staticClass: "cover",
      attrs: {
        src: it.contenturl
      }
    }), _c("div", {
      staticClass: "actions"
    }, [_c("span", {
      staticClass: "actions_item"
    }, [_c("a-icon", {
      staticClass: "icons",
      staticStyle: {
        color: "white"
      },
      attrs: {
        type: "eye"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(_vm.urls, "pic", index);
        }
      }
    })], 1), _c("span", {
      staticClass: "actions_item"
    }, [_c("a-icon", {
      staticClass: "icons",
      staticStyle: {
        color: "white"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteImg(index);
        }
      }
    })], 1)]), _c("a-progress", {
      attrs: {
        percent: it.percent || 0
      }
    })], 1);
  }), 0)], 1)], _c("a-form", _vm._g(_vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.collapsed,
      expression: "!collapsed"
    }],
    attrs: {
      form: _vm.form,
      layout: "vertical",
      labelCol: {
        style: "width: 180px; text-align: right;"
      },
      labelAlign: "right"
    }
  }, "a-form", _vm.$attrs, false), _vm.$listeners), [_vm.postType == 1 ? [_c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.title"), "\uFF1A")
    }
  }, [_c("TagSearchInput", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: true
        }, {
          max: 1000
        }],
        initialValue: _vm.videoName
      }],
      expression: "[\n            'title',\n            {\n              rules: [\n                { required: true },\n                {\n                  max: 1000,\n                },\n              ],\n              initialValue: videoName\n            },\n          ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "max-length": 1000,
      placeholder: _vm.$t("dcReview.form.title"),
      autocomplete: "off",
      type: "textarea"
    }
  }), !_vm.item.id ? _c("div", {
    staticClass: "form-process-wrap"
  }, [_c("div", {
    staticClass: "form-process"
  }, [_c("a-progress", {
    attrs: {
      percent: _vm.item.percent || 0
    }
  })], 1), _c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.status == "done",
      expression: "item.status == 'done'"
    }],
    staticClass: "process-icon",
    attrs: {
      type: "eye"
    },
    on: {
      click: function click($event) {
        return _vm.previewImg(_vm.item.contenturl, "video");
      }
    }
  }), _c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.status == "exception",
      expression: "item.status == 'exception'"
    }],
    staticClass: "process-icon error",
    attrs: {
      type: "redo"
    },
    on: {
      click: _vm.reupload
    }
  })], 1) : _c("Upload2", {
    staticClass: "form-process-wrap",
    attrs: {
      value: _vm.contenturl,
      supportFileType: ".mp4"
    },
    on: {
      started: _vm.started,
      ended: _vm.ended,
      previewImg: _vm.previewImg
    }
  })], 1)] : [_c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.title"), "\uFF1A")
    }
  }, [_c("TagSearchInput", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: true
        }, {
          max: 1000
        }],
        initialValue: _vm.videoName
      }],
      expression: "[\n            'title',\n            {\n              rules: [\n                { required: true },\n                {\n                  max: 1000,\n                },\n              ],\n              initialValue: videoName\n            },\n          ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "max-length": 1000,
      placeholder: _vm.$t("dcReview.form.title"),
      autocomplete: "off",
      type: "textarea"
    }
  })], 1)], _c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.creator"), "\uFF1A")
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["creator", {
        rules: [{
          required: true
        }],
        initialValue: _vm.item.creator
      }],
      expression: "[\n          'creator',\n          {\n            rules: [{ required: true }],\n            initialValue: item.creator,\n          },\n        ]"
    }],
    staticClass: "user-select",
    attrs: {
      dropdownClassName: "user-select",
      allowClear: true,
      "show-search": "",
      size: "large",
      labelInValue: "",
      placeholder: _vm.$t("dcReview.form.creatorNote"),
      "filter-option": false,
      "not-found-content": _vm.uloading ? undefined : null
    },
    on: {
      search: _vm.handleUserSearch
    }
  }, [_vm.uloading ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.userSource, function (d) {
    return _c("a-select-option", {
      key: d.uid,
      staticClass: "user-select-item",
      attrs: {
        title: d.username,
        value: d.uid
      }
    }, [_c("img", {
      staticClass: "user-select-avatar",
      attrs: {
        src: d.headimgurl
      }
    }), _c("span", [_vm._v(_vm._s(d.username))])]);
  })], 2), _c("div", {
    staticClass: "cover-remark"
  }, [_vm._v(" " + _vm._s(_vm.$t("dcReview.form.note")) + ": " + _vm._s(_vm.$t("dcReview.form.creatorNote")) + " ")])], 1), _c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.tag"), "\uFF1A")
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["labels", {
        rules: [{
          required: false
        }],
        initialValue: _vm.labelids
      }],
      expression: "[\n          'labels',\n          {\n            rules: [{ required: false }],\n            initialValue: labelids,\n          },\n        ]"
    }],
    attrs: {
      options: _vm.tagSource,
      "show-search": {
        filterCascader: _vm.filterCascader
      },
      placeholder: _vm.$t("dcReview.form.tag"),
      fieldNames: {
        label: "name",
        value: "id",
        children: "children"
      },
      size: "large"
    },
    scopedSlots: _vm._u([{
      key: "displayRender",
      fn: function fn(_ref) {
        var labels = _ref.labels;
        return [labels.length ? _c("a-tag", {
          staticStyle: {
            height: "20px"
          }
        }, [_vm._v(" " + _vm._s(labels[labels.length - 1]) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.cover"), "\uFF1A")
    }
  }, [_c("Upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["coverurl", {
        rules: [{
          required: true
        }],
        initialValue: _vm.coverurl
      }],
      expression: "[\n          'coverurl',\n          {\n            rules: [{ required: true }],\n            initialValue: coverurl,\n          },\n        ]"
    }],
    attrs: {
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion,
      fileSize: 1 * 1024,
      force: false,
      supportFileType: ".jpg,.jpeg,.png,.gif"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.allowDownload"), "\uFF1A")
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isdownload", {
        rules: [{
          required: true
        }],
        initialValue: [0, 1].includes(_vm.item.isdownload) ? _vm.item.isdownload : 1
      }],
      expression: "[\n          'isdownload',\n          {\n            rules: [{ required: true }],\n            initialValue: [0, 1].includes(item.isdownload)\n              ? item.isdownload\n              : 1,\n          },\n        ]"
    }],
    attrs: {
      options: [{
        label: _vm.$t("dcReview.form.allow"),
        value: 1
      }, {
        label: _vm.$t("dcReview.form.notAllowed"),
        value: 0
      }]
    }
  })], 1), _vm.postType == 1 ? _c("a-form-item", {
    attrs: {
      label: "".concat(_vm.$t("dcReview.form.uploadSubtitle"), "\uFF1A")
    }
  }, [_c("Upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["subtitleurl", {
        rules: [{
          required: false
        }],
        initialValue: _vm.item.subtitleurl
      }],
      expression: "[\n          'subtitleurl',\n          {\n            rules: [{ required: false }],\n            initialValue: item.subtitleurl,\n          },\n        ]"
    }],
    attrs: {
      listType: "text",
      max: 1,
      force: false,
      uploadType: "vtt",
      supportFileType: ".vtt"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasVideo,
      expression: "!hasVideo"
    }],
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.$t("dcReview.form.noFile", [_vm.typeListObj[_vm.postType]])) + " ")])], 2), _vm.postType == 1 ? [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.collapsed,
      expression: "collapsed"
    }],
    staticClass: "form-collapsed"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.videoName))]), _c("div", {
    staticClass: "form-process-wrap"
  }, [_c("div", {
    staticClass: "form-process"
  }, [_c("a-progress", {
    attrs: {
      percent: _vm.item.percent || 0
    }
  })], 1), _c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.status == "done",
      expression: "item.status == 'done'"
    }],
    staticClass: "process-icon",
    attrs: {
      type: "eye"
    },
    on: {
      click: function click($event) {
        return _vm.previewImg(_vm.contenturl, "video");
      }
    }
  }), _c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.status == "exception",
      expression: "item.status == 'exception'"
    }],
    staticClass: "process-icon error",
    attrs: {
      type: "redo"
    },
    on: {
      click: _vm.reupload
    }
  })], 1)])] : _vm._e(), _c("PreviewMedia", {
    attrs: {
      isShow: _vm.previewVisible && _vm.previewType == "video",
      previewSource: _vm.previewSource,
      mediaType: "video"
    },
    on: {
      closePreview: _vm.previewHandleCancel
    }
  }), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.deleteTipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible,
      loading: _vm.deleting
    },
    on: {
      cancle: _vm.handleDeleteCancle,
      submit: _vm.handleDeleteSubmit
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };