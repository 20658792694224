import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.array.concat.js";
import { uploadImg as uploadImgApi } from "@/api/common";

// 获取视频中间帧并上传
export function getVideoFirstFrame(url) {
  return new Promise(function (resolve, reject) {
    try {
      getVideoBasicInfo(url).then(function (videoInfo) {
        return getVideoPosterInfo(videoInfo);
      }).then(function (data) {
        return uploadImg(data);
      }).then(function (data) {
        // {duration: 2, url: 'xxx'}
        resolve(data);
      }).catch(function (err) {
        reject(err);
      });
    } catch (error) {
      // console.log('看有没有错误出来', error);
      reject(error);
    }
  });
}
export function base64toFile(dataurl) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "file";
  try {
    var arr = dataurl.split(",");
    var tempstr = arr[0] || "";
    var matchstr = tempstr.match(/:(.*?);/) || [];
    var mime = matchstr[1];
    var suffix = mime.split("/")[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "".concat(filename, ".").concat(suffix), {
      type: mime
    });
  } catch (error) {
    return null;
  }
}
// 获取视频基本信息
export function getVideoBasicInfo(videoSrc) {
  return new Promise(function (resolve, reject) {
    var video = document.createElement("video");
    video.src = videoSrc;
    // 视频一定要添加预加载
    // video.preload = 'auto'
    // 视频一定要同源或者必须允许跨域
    video.setAttribute("controls", "controls");
    video.setAttribute("crossorigin", "anonymous");
    // 监听：异常
    video.addEventListener("error", function () {
      reject("视频获取失败了");
    });
    var videoInfo = {};
    // 监听：加载完成基本信息,设置要播放的时常
    video.addEventListener("loadedmetadata", function () {
      video.currentTime = 1; // 0.5 * video.duration;
      videoInfo = {
        video: video,
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration
      };
    });
    video.addEventListener("timeupdate", function () {
      resolve(videoInfo);
    });
  });
}
// 将获取到的视频信息，转化为图片资源
export function getVideoPosterInfo(videoInfo) {
  return new Promise(function (resolve) {
    var video = videoInfo.video,
      width = videoInfo.width,
      height = videoInfo.height,
      duration = videoInfo.duration;
    video.addEventListener("canplay", function () {
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, width, height);
      var posterUrl = canvas.toDataURL("image/png");
      resolve({
        duration: duration,
        posterUrl: posterUrl
      });
    });
  });
}
// 上传图片
export function uploadImg(data) {
  return new Promise(function (resolve, reject) {
    var posterUrl = data.posterUrl,
      duration = data.duration;
    var file = base64toFile(posterUrl);
    var formData = new FormData();
    formData.append("file", file);
    uploadImgApi(formData).then(function (res) {
      resolve({
        duration: duration,
        url: res
      });
    }).catch(function () {
      reject("视频中间帧上传失败");
    });
  });
}