import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.slice.js";
import { mapGetters } from "vuex";
import Upload from "./upload";
import Upload2 from "./upload2";
import previewMixin from "@/utils/previewMixin";
import { WhitelistApi } from "@/api";
import { getVideoFirstFrame, getVideoBasicInfo } from "@/utils/video";
import PreviewMedia from "@/components/PreviewMedia.vue";
import DeleteMixin from "@/utils/deleteMixin";
import { filenameWithoutExtenstion, isVideo, flattenTreeData } from "@/utils/help";
import { uploadImg, uploadvideo2 } from "@/api/common";
import draggable from "vuedraggable";
import TagSearchInput from '@/components/TagSearchInput.vue';
export default {
  components: {
    Upload: Upload,
    PreviewMedia: PreviewMedia,
    Upload2: Upload2,
    draggable: draggable,
    TagSearchInput: TagSearchInput
  },
  mixins: [previewMixin, DeleteMixin],
  props: {
    item: {
      type: [Object, File],
      default: function _default() {}
    },
    tagSource: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    postType: {
      type: Number,
      default: 1
    },
    photoList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters("dcVideo", ["typeListObj"])), {}, {
    status: function status() {
      return this.item.status == "error" ? "exception" : this.item.status;
    },
    completed: function completed() {
      if (this.postType == 1) {
        var contenturl = this.item.id ? this.contenturl : this.item.contenturl;
        return !!contenturl;
      } else {
        // photo is uploaded.
        var isCompleted = true;
        if (this.photoList.length == 0) return false;
        this.photoList.some(function (it) {
          if (it.status != 'done') {
            isCompleted = false;
            return true;
          }
          return false;
        });
        return isCompleted;
      }
    },
    flatten: function flatten() {
      return flattenTreeData(this.tagSource);
    },
    labelids: function labelids() {
      var ids = (this.item.labels || []).map(function (item) {
        return item.id;
      });
      if (!ids || ids.length == 0) return [];
      var labelid = ids[0];
      function getAllparent(flatten, id) {
        var data = [];
        var cur = flatten.find(function (it) {
          return it.id == id;
        });
        if (cur) {
          data.push(cur.id);
          // console.log(cur);
          if (cur.parentid) {
            data.push.apply(data, _toConsumableArray(getAllparent(flatten, cur.parentid)));
          }
        }
        return data;
      }
      var labelids = getAllparent(this.flatten, labelid);
      // console.log(labelid, labelids, this.flatten);
      return labelids.reverse();
    },
    videoName: function videoName() {
      if (this.postType == 1) {
        var _this$item;
        return (_this$item = this.item) !== null && _this$item !== void 0 && _this$item.name ? filenameWithoutExtenstion(this.item.name) : this.item.title;
      } else {
        if (this.item.title) return this.item.title;
        return this.photoList.length > 0 ? filenameWithoutExtenstion(this.photoList[0].name) : '';
      }
    },
    urls: function urls() {
      if (this.postType == 1) return '';
      return this.photoList.map(function (it) {
        return it.contenturl;
      }).join();
    }
  }),
  watch: {
    contenturl: function contenturl(val) {
      var _this = this;
      if (this.postType == 1 && val) {
        this.coverurl = "";
        getVideoBasicInfo(val).then(function (data) {
          _this.duration = data.duration;
        }).catch(function () {});
        getVideoFirstFrame(val).then(function (data) {
          _this.coverurl = data.url;
        }).catch(function () {});
      }
    },
    photoList: function photoList(val) {
      console.log(this.item.coverurl, this.coverurl);
      if (this.postType == 1 || this.coverurl) return;
      if (this.postType == 2 && this.item.coverurl) this.coverurl = this.item.coverurl;
      if (!this.coverurl && !this.item.id && Array.isArray(val) && val.length > 0) this.coverurl = val[0].contenturl;
    }
  },
  created: function created() {
    var _this2 = this;
    if (this.postType == 1 && !this.coverurl) {
      // 视频文件进行截图处理
      var windowURL = window.URL || window.webkitURL;
      var videoURL = windowURL.createObjectURL(this.item);
      getVideoBasicInfo(videoURL).then(function (data) {
        _this2.duration = data.duration;
      }).catch(function () {});
      getVideoFirstFrame(videoURL).then(function (data) {
        _this2.coverurl = data.url;
      }).catch(function () {});
    }
    this.initUserSource();
    // this.selectTags = this.topicnames
  },
  data: function data() {
    return {
      imgProportion: [1920, 800],
      collapsed: false,
      form: this.$form.createForm(this, {
        name: this.item.uid
      }),
      uloading: false,
      userSource: [],
      coverurl: this.item.coverurl,
      //封面图
      duration: this.item.duration,
      //时间
      hasVideo: true,
      contenturl: this.item.contenturl,
      creatorKey: "creators"
      // selectTags: [],
    };
  },

  methods: {
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      if (this.searchMode == "remote") return true;
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleUserSearch: function handleUserSearch(val) {
      var _this3 = this;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.userSource = [];
      if (!val) return;
      this.uloading = true;
      this.timer = setTimeout(function () {
        WhitelistApi.searchUsername({
          name: val
        }).then(function (res) {
          _this3.userSource = res;
          _this3.uloading = false;
        }).catch(function () {
          _this3.uloading = false;
        });
      }, 500);
    },
    handleDeleteSubmit: function handleDeleteSubmit() {
      this.$emit("removed", this.item.uid);
    },
    collapse: function collapse() {
      this.collapsed = !this.collapsed;
    },
    // 提交校验
    submit: function submit() {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.form.validateFields(function (err, values) {
          if (!_this4.completed) {
            _this4.hasVideo = false;
            reject();
            return;
          }
          _this4.hasVideo = true;
          if (!err) {
            var _params$creator;
            var fillable = ["type", "contenturl", "coverurl", "title", "userid", "labels", "duration", "subtitleurl", "isdownload", "topicnames"];
            var regex = /#[^\s#]+/g;
            var topicnames = values.title.match(regex);
            var params = _objectSpread(_objectSpread({}, values), {}, {
              topicnames: topicnames
            });
            var data = {};
            if (_this4.postType == 1) {
              var _this4$item;
              params.contenturl = (_this4$item = _this4.item) !== null && _this4$item !== void 0 && _this4$item.id ? _this4.contenturl : _this4.item.contenturl;
              params.duration = Math.floor(_this4.duration);
            } else {
              params.contenturl = _this4.photoList.map(function (it) {
                return it.contenturl;
              }).join();
              params.duration = 0;
              params.subtitleurl = '';
            }
            params.type = _this4.postType;
            params.userid = (_params$creator = params.creator) === null || _params$creator === void 0 ? void 0 : _params$creator.key;
            if (params.labels && params.labels.length) {
              params.labels = [params.labels[params.labels.length - 1]];
            }
            fillable.forEach(function (v) {
              data[v] = params[v];
            });
            _this4.saveToHistory(params.creator);
            // console.log('数据提交了', data, values);
            resolve(_objectSpread({}, data));
          } else {
            reject();
          }
        });
      });
    },
    reupload: function reupload() {
      var file = this.item;

      // 重新上传
      var name = file.name;
      var _ = this;
      // console.log(name, file);
      // 图片转base64
      var formData = new FormData();
      formData.append("file", file.originFileObj);
      // 上传
      var uploaded;
      file.status = "uploading";
      if (isVideo(name)) {
        var config = {
          onUploadProgress: function onUploadProgress(ev) {
            // ev - axios 上传进度实例，上传过程触发多次
            // ev.loaded 当前已上传内容的大小，ev.total - 本次上传请求内容总大小
            // console.log(ev);
            var percent = Math.floor(ev.loaded / ev.total * 100);
            // 计算出上传进度，调用组件进度条方法
            // file.onProgress({ percent });
            if (percent < 100) file.percent = percent;
          }
        };
        uploaded = uploadvideo2(formData, config);
      } else {
        uploaded = uploadImg(formData);
      }
      uploaded.then(function (res) {
        file.status = "done";
        file.response = res;
        file.contenturl = res;
        file.percent = 100;
        // file.onSuccess(res, file);
        // console.log(file);
        _.toast(_.$t('common.success'), "success");
      }).catch(function (err) {
        file.status = "exception";
        // file.onError(err);
        // console.log(file);
        _.toast(_.$t('common.failed'), "error");
      });
    },
    filterCascader: function filterCascader(inputValue, path) {
      return path.some(function (option) {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
      });
    },
    started: function started() {
      // console.log('开始上传了...');
      this.contenturl = "";
    },
    ended: function ended(url) {
      var _this5 = this;
      // console.log('上传结束了', url);
      getVideoBasicInfo(url).then(function (data) {
        _this5.contenturl = url;
        _this5.duration = data.duration;
      }).catch(function () {});
    },
    saveToHistory: function saveToHistory(creator) {
      if (!creator) return;
      var key = creator.key,
        label = creator.label;
      var headimgurl, username;
      if (label instanceof Array) {
        label.forEach(function (item) {
          if (item.tag == "img") {
            var _item$data$attrs;
            headimgurl = (_item$data$attrs = item.data.attrs) === null || _item$data$attrs === void 0 ? void 0 : _item$data$attrs.src;
          } else if (item.tag == "span") {
            username = item.children[0].text;
          }
        });
        var creators = window.localStorage.getItem(this.creatorKey);
        if (creators) {
          creators = JSON.parse(creators);
        } else {
          creators = [];
        }
        var isExists = false;
        creators.some(function (it) {
          if (it.uid == key) {
            it.num += 1;
            it.headimgurl = headimgurl || it.headimgurl;
            isExists = true;
            return true;
          }
        });
        if (!isExists) {
          creators.push({
            uid: key,
            username: username,
            headimgurl: headimgurl,
            num: 1
          });
        }
        window.localStorage.setItem(this.creatorKey, JSON.stringify(creators));
      }
    },
    // 初始话用户数据
    initUserSource: function initUserSource() {
      var creators = window.localStorage.getItem(this.creatorKey);
      if (creators) {
        creators = JSON.parse(creators);
      } else {
        creators = [];
      }
      creators.sort(function (a, b) {
        return b.num - a.num;
      });
      this.userSource = creators.slice(0, 10);
    },
    deleteImg: function deleteImg(index) {
      this.$emit('delimg', index);
    }
  }
};