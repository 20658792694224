import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import { uploadImg, uploadvideo2, uploadsubtitle } from "@/api/common";
import { isImage, isVideo, fileExtension, isSubtitle } from "@/utils/help";
import { ByteConvert } from '@/utils/help';
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "text"
    },
    /**
     * 是否展示 uploadList, 可设为一个对象，用于单独设定 showPreviewIcon 和 showRemoveIcon
     */
    showUploadList: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: [Array, String],
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 最大上传数量
     */
    max: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * 限制上传文件
     */
    fileSize: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * 如果value 为String，并用符号分割，可以用此属性做处理。
     */
    symbol: {
      type: String,
      required: false,
      default: undefined
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showSupport: {
      //是否显示支持类型
      type: Boolean,
      default: true
    },
    // 支持的文件类型 .mp4,.avi等
    supportFileType: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 像素尺寸
    pixelSize: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 上传类型
    uploadType: {
      type: String,
      default: 'pic'
    }
  },
  computed: {
    fileTypeTxt: function fileTypeTxt() {
      return this.uploadType == 'video' ? this.$t('common.fileupload.typeVideo') : this.uploadType == 'pic' ? this.$t('common.fileupload.typePic') : this.$t('common.fileupload.typeFile');
    },
    formatFilesize: function formatFilesize() {
      return ByteConvert(this.fileSize * 1024);
    }
  },
  data: function data() {
    return {
      fileList: []
    };
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    value: function value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    previewImg: function previewImg(file) {
      this.$emit("previewImg", file.url, this.uploadType);
    },
    init: function init() {
      var value = this.value,
        symbol = this.symbol;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      } else if (typeof value === "string") {
        if (symbol) {
          this.initFileList(value.split(symbol));
        } else if (value.indexOf(",") > -1) {
          this.initFileList(value.split(","));
        } else {
          // value 是string类型，没有Symbol处理时，为单张图片
          this.fileList = [];
          if (value) {
            this.initFileList([value]);
          }
        }
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item,
          url: item
        };
        _this.fileList.push(temp);
      });
    },
    beforeUpload: function beforeUpload(file) {
      if (this.supportFileType) {
        var extenstion = fileExtension(file.name);
        var isExists = false;
        this.supportFileType.split(",").some(function (ext) {
          if (ext.indexOf(".", ext) != -1) {
            ext = ext.split(".");
            ext = ext.length > 0 ? ext[ext.length - 1] : "";
          }
          if (extenstion.toLowerCase() == ext.toLowerCase()) {
            isExists = true;
            return true;
          }
        });
        if (!isExists) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
        // console.log(this.supportFileType, file.name, isExists);
      }

      if (this.uploadType == 'video') {
        if (!isVideo(file.name)) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
      } else if (this.uploadType == 'pic') {
        if (!isImage(file.name)) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
      }
      if (this.fileList.length >= this.max) {
        this.toast(this.$t('common.fileupload.numMaximum', [this.max]), "error");
        return false;
      }
      if (this.fileSize && file.size / 1024 > this.fileSize) {
        /**
         * 判断上传图片是否超出大小
         *
         * 暂时留白
         */

        this.toast(this.$t('common.fileupload.formatFilesize', [this.formatFilesize]), "error");
        return false;
      }
      // 上传之前
      file.status = "uploading";
      this.fileList.push(file);
    },
    handleChange: function handleChange(event) {
      // 上传状态修改回调
      if (event.file.status === "removed") {
        this.fileList = event.fileList;
        this.deliveryData();
      }
      if (event.file.status === "done") {
        this.deliveryData();
      } else if (event.file.status === "error") {
        window.console.error(this.$t('common.failed'));
      }
    },
    deliveryData: function deliveryData() {
      var imgList = [];
      this.fileList.forEach(function (item) {
        imgList.push(item.url);
      });
      this.fileList = [];

      /**
       * 如果按字符串处理 则按字符串返回
       */
      if (this.symbol && this.max > 1) {
        imgList = imgList.join(this.symbol);
      }

      /**
       * 返回一个组数
       */
      if (this.max === 1) {
        this.$emit("change", imgList[0]);
        // 一张图片的时候 添加双向绑定V-model
        this.$emit("input", imgList[0]);
      } else {
        this.$emit("input", imgList);
        this.$emit("change", imgList);
      }
    },
    handleUpload: function handleUpload(file) {
      var _file$file = file.file,
        uid = _file$file.uid,
        name = _file$file.name,
        status = _file$file.status;
      var _ = this;
      var temp = {
        uid: uid,
        name: name,
        status: status
      };
      // 图片转base64
      var formData = new FormData();
      formData.append("file", file.file);
      // 上传
      var uploaded;
      if (this.uploadType == 'video') {
        var config = {
          onUploadProgress: function onUploadProgress(ev) {
            // ev - axios 上传进度实例，上传过程触发多次
            // ev.loaded 当前已上传内容的大小，ev.total - 本次上传请求内容总大小
            var percent = Math.floor(ev.loaded / ev.total * 100);
            // 计算出上传进度，调用组件进度条方法
            file.onProgress({
              percent: percent
            });
          }
        };
        uploaded = uploadvideo2(formData, config);
      } else if (this.uploadType == 'pic') {
        uploaded = uploadImg(formData);
      } else {
        uploaded = uploadsubtitle(formData);
      }
      uploaded.then(function (res) {
        file.onSuccess(res, file);
        _.toast(_.$t('common.success'), "success");
        temp.status = "done";
        temp.url = res;
        _.fileList.splice(_.fileList.length - 1, 1, temp);
        _.handleChange({
          file: temp
        });
      }).catch(function (err) {
        file.onError(err);
        _.toast(_.$t('common.failed'), "error");
        temp.status = "error";
        _.fileList.splice(_.fileList.length - 1, 1);
        _.handleChange({
          file: temp
        });
      });
    }
  }
};