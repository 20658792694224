import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import { uploadImg, uploadvideo2, uploadsubtitle } from "@/api/common";
import { isImage, isVideo, fileExtension, ByteConvert } from "@/utils/help";
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "text"
    },
    /**
     * 是否展示 uploadList, 可设为一个对象，用于单独设定 showPreviewIcon 和 showRemoveIcon
     */
    showUploadList: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: [Array, String],
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 限制上传文件
     */
    fileSize: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * 如果value 为String，并用符号分割，可以用此属性做处理。
     */
    symbol: {
      type: String,
      required: false,
      default: undefined
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showSupport: {
      //是否显示支持类型
      type: Boolean,
      default: true
    },
    // 支持的文件类型 .mp4,.avi等
    supportFileType: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 像素尺寸
    pixelSize: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 上传类型
    uploadType: {
      type: String,
      default: 'video'
    },
    // 上传模式
    mode: {
      type: String,
      default: 'single' // 单文件模式，一直覆盖其他的
    }
  },

  computed: {
    fileTypeTxt: function fileTypeTxt() {
      return this.uploadType == 'video' ? this.$t('common.fileupload.typeVideo') : this.uploadType == 'pic' ? this.$t('common.fileupload.typePic') : this.$t('common.fileupload.typeFile');
    },
    state: function state() {
      return this.fileList.length ? this.fileList[0].status : 'normal';
    },
    formatFilesize: function formatFilesize() {
      return ByteConvert(this.fileSize * 1024);
    }
  },
  data: function data() {
    return {
      fileList: [],
      max: 1,
      show: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    value: function value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    previewImg: function previewImg(file) {
      console.log('file', file);
      this.$emit("previewImg", file.response, this.uploadType);
    },
    init: function init() {
      var value = this.value,
        symbol = this.symbol;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      } else if (typeof value === "string") {
        if (symbol) {
          this.initFileList(value.split(symbol));
        } else if (value.indexOf(",") > -1) {
          this.initFileList(value.split(","));
        } else {
          // value 是string类型，没有Symbol处理时，为单张图片
          this.fileList = [];
          if (value) {
            this.initFileList([value]);
          }
        }
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item,
          response: item,
          percent: 100
        };
        if (_this.mode == 'single') {
          _this.fileList = [temp]; // 只显示一个呀
        } else {
          _this.fileList.push(temp);
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      if (this.supportFileType) {
        var extenstion = fileExtension(file.name);
        var isExists = false;
        this.supportFileType.split(",").some(function (ext) {
          if (ext.indexOf(".", ext) != -1) {
            ext = ext.split(".");
            ext = ext.length > 0 ? ext[ext.length - 1] : "";
          }
          if (extenstion.toLowerCase() == ext.toLowerCase()) {
            isExists = true;
            return true;
          }
        });
        if (!isExists) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
        // console.log(this.supportFileType, file.name, isExists);
      }

      if (this.uploadType == 'video') {
        if (!isVideo(file.name)) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
      } else if (this.uploadType == 'pic') {
        if (!isImage(file.name)) {
          this.toast(this.$t('common.fileupload.formatType', [this.supportFileType]), "error");
          return false;
        }
      }

      // if (this.fileList.length >= this.max) {
      //   this.toast("你已达到最大上传数量", "error");
      //   return false;
      // }
      if (this.fileSize && file.size / 1024 > this.fileSize) {
        /**
         * 判断上传图片是否超出大小
         *
         * 暂时留白
         */

        this.toast(this.$t('common.fileupload.formatFilesize', [this.formatFilesize]), "error");
        return false;
      }
      this.show = true;
      // 上传之前
      file.status = "uploading";
      if (this.mode == 'single') {
        // this.current = {uid: file.uid, name: file.name, percent: file.percent, status: file.status};
        this.fileList = [file]; // 只显示一个呀
      } else {
        this.fileList.push(file);
      }
      this.$emit('started');
    },
    handleChange: function handleChange(event) {
      var file = event.file;
      if (file.percent != 100) {
        this.$set(this.fileList, 0, file);
      }

      // });
      // this.current = {uid: file.uid, name: file.name, percent: file.percent, status: file.status};
      // this.$set(this.current, 'percent', file.percent);
      // this.current.status = file.status;
      // this.current.percent = file.percent;
      // console.log(event, this.fileList);
      // console.log('event', event);
      // // 上传状态修改回调
      // if (event.file.status === "removed") {
      //   this.fileList = event.fileList;
      //   this.deliveryData();
      // }

      // if (event.file.status === "done") {
      //   this.deliveryData();
      // } else if (event.file.status === "error") {
      //   window.console.error("上传失败");
      // }
    },
    deliveryData: function deliveryData() {
      var imgList = [];
      this.fileList.forEach(function (item) {
        imgList.push(item.url);
      });

      /**
       * 如果按字符串处理 则按字符串返回
       */
      if (this.symbol && this.max > 1) {
        imgList = imgList.join(this.symbol);
      }

      /**
       * 返回一个组数
       */
      if (this.max === 1) {
        this.$emit("change", imgList[0]);
        // 一张图片的时候 添加双向绑定V-model
        this.$emit("input", imgList[0]);
      } else {
        this.$emit("input", imgList);
        this.$emit("change", imgList);
      }
    },
    handleUpload: function handleUpload(file) {
      var _this2 = this;
      //   const { uid, name, status } = file.file;
      var _ = this;
      // const temp = { uid, name, status };
      // 图片转base64
      var formData = new FormData();
      formData.append("file", file.file);
      // 上传
      var uploaded;
      if (this.uploadType == 'video') {
        var config = {
          onUploadProgress: function onUploadProgress(ev) {
            // ev - axios 上传进度实例，上传过程触发多次
            // ev.loaded 当前已上传内容的大小，ev.total - 本次上传请求内容总大小
            var percent = Math.floor(ev.loaded / ev.total * 100);
            // 计算出上传进度，调用组件进度条方法
            file.onProgress({
              percent: percent
            });
            // console.log('当前进度', percent,  this.fileList, uid);
            // this.fileList.forEach((item) => {
            //   if(item.uid == uid){
            //     item.percent = percent;
            //   }
            // });
          }
        };

        uploaded = uploadvideo2(formData, config);
      } else if (this.uploadType == 'pic') {
        uploaded = uploadImg(formData);
      } else {
        uploaded = uploadsubtitle(formData);
      }
      uploaded.then(function (res) {
        file.onSuccess(res, file);
        _.toast(_.$t('common.success'), "success");
        var item = _this2.fileList[0];
        item.percent = 100;
        item.contenturl = res;
        item.status = 'done';
        _this2.$set(_this2.fileList, 0, item);
        _.$emit('ended', res);
        // temp.status = "done";
        // temp.url = res;
        // _.fileList.splice(_.fileList.length - 1, 1, temp);
        // _.handleChange({ file: temp });
        // this.fileList = [file];
      }).catch(function (err) {
        file.onError(err);
        _.toast(_.$t('common.failed'), "error");
        var item = _this2.fileList[0];
        item.status = 'exception';
        _this2.$set(_this2.fileList, 0, item);
        // temp.status = "error";
        // _.fileList.splice(_.fileList.length - 1, 1);
        // _.handleChange({ file: temp });
        // this.fileList = [file];
      });
    }
  }
};