import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 105px"
      },
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.postId") + "："
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["videoids", {
        rules: [{
          required: true,
          message: _vm.$t("dcReview.postId") + "，" + _vm.$t("common.multipleRowsByEachID")
        }],
        initialValue: _vm.ids
      }],
      expression: "[\n            'videoids',\n            {\n              rules: [{ required: true, message: $t('dcReview.postId')+'，'+$t('common.multipleRowsByEachID') }],\n              initialValue: ids,\n            },\n          ]"
    }],
    attrs: {
      placeholder: _vm.$t("dcReview.postId") + "，" + _vm.$t("common.multipleRowsByEachID"),
      "allow-clear": "",
      rows: "5"
    }
  })], 1), _vm.action == "unpass" ? _c("a-form-item", {
    attrs: {
      label: _vm.$t("dcReview.reason") + "："
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: _vm.$t("common.pleaseSelect") + _vm.$t("dcReview.reason"),
      "show-search": "",
      filterOption: _vm.filterOption,
      dropdownMatchSelectWidth: false,
      dropdownClassName: "kw-select-dropdown"
    },
    on: {
      change: _vm.handleReason
    },
    scopedSlots: _vm._u([{
      key: "dropdownRender",
      fn: function fn(menu) {
        return _c("div", {}, _vm._l(_vm.templates, function (item) {
          return _c("div", {
            key: item.code,
            on: {
              click: function click($event) {
                return _vm.handleReason(item.code);
              }
            }
          }, [_c("a-tooltip", {
            attrs: {
              destroyTooltipOnHide: true,
              placement: "topLeft"
            }
          }, [_c("template", {
            slot: "title"
          }, [_vm._v(" " + _vm._s(item.message) + " ")]), _vm._v(" " + _vm._s(item.message) + " ")], 2)], 1);
        }), 0);
      }
    }], null, false, 2153466096)
  }), _c("div", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["reason", {
        rules: [{
          required: true,
          message: _vm.$t("common.pleaseEnter"),
          whitespace: true
        }, {
          max: 200
          // message: '最多200个字符',
        }],

        initialValue: _vm.templateMsg
      }],
      expression: "[\n              'reason',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('common.pleaseEnter'),\n                    whitespace: true,\n                  },\n                  {\n                    max: 200,\n                    // message: '最多200个字符',\n                  },\n                ],\n                initialValue: templateMsg,\n              },\n            ]"
    }],
    attrs: {
      "allow-clear": "",
      autoComplete: "off",
      size: "large",
      placeholder: _vm.$t("common.pleaseEnter"),
      disabled: _vm.isDisabled,
      "max-length": 200
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1)], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: _vm.$t("common.remark") + "："
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memo", {
        rules: [{
          max: 200
          // message: '最多200个字符',
        }],

        initialValue: _vm.item.memo
      }],
      expression: "[\n            'memo',\n            {\n              rules: [\n                {\n                  max: 200,\n                  // message: '最多200个字符',\n                },\n              ],\n              initialValue: item.memo,\n            },\n          ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: _vm.$t("common.pleaseEnter"),
      disabled: _vm.isDisabled,
      "max-length": 200
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };