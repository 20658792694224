// 删除功能封装函数
export default {
  data: function data() {
    return {
      deleteTipsDesc: "",
      isDeleteVisible: false,
      isshowDeleteVisible: true,
      selectId: '',
      deleting: false
    };
  },
  methods: {
    // 取消删除
    handleDeleteCancle: function handleDeleteCancle() {
      this.isDeleteVisible = false;
    },
    // 确定删除
    handleDeleteSubmit: function handleDeleteSubmit() {
      var _this = this;
      this.$emit("delete", this.selectId);
      setTimeout(function () {
        _this.isDeleteVisible = false;
      }, 1000);
    },
    handleDelete: function handleDelete(record) {
      if (this.isshowDeleteVisible) {
        this.deleteTipsDesc = "确定删除吗？";
        this.isDeleteVisible = true;
        this.selectId = record;
      } else {
        //删除当前行商品
        this.$emit("delete", record);
      }
    }
  }
};